.skill-container {
  color: #a9adb8;
  overflow: hidden;
  padding: 5%;
  h1 {
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    color: #fec544;
  }
  .skill-info {
    display: flex;
    flex-direction: row;
    .skill-column {
      justify-content: center;
      align-items: center;
      width: 50%;
      display: flex;
      flex-direction: column;
      padding-top: 20px;
      .skill-type {
        width: 90%;
        padding: 1% 5%;
        display: flex;
        align-items: flex-start;
        text-align: start;
    
        p {
          text-align: left;
          word-wrap: break-word;
          width: 100%;
        }
        h3 {
          min-width: 150px;
          text-align: left;
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .skill-container {
    .skill-info {
      display: flex;
      flex-direction: column;
      width: 100%;
      .skill-column {
        width: 100%;
        .skill-type {
          padding: 1%;
          h3 {
            font-size: 18px;
            font-weight: 500;
          }
          p {
            font-size: 16px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 481px) and (max-width: 820px) {
  .skill-container {
    .skill-info {
      display: flex;
      flex-direction: column;
      width: 100%;
      .skill-column {
        width: 100%;
        .skill-type {
          padding: 1%;
        }
      }
    }
  }
}
