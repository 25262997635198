.footer {
  background-color: #070b15;
  color: #fff;
  padding: 4rem 6rem;
}

.top {
  display: flex;
  align-items: center;
  text-align: start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.top i {
  color: #fff;
  font-size: 2rem;
  margin-left: 1rem;
}


@media screen and (max-width: 850px) {
  .footer {
    padding: 4rem 2rem;
  }

  .footer i {
    margin: 1rem 1rem 0 0;
  }
}
