.about {
  padding-top: 100px;
  color: #a9adb8;
  h1 {
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    color: #fec544;
  }
  .about-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 40px;
    padding: 5%;
    .image {
      width: 50%;
      max-height: 500px;
      img {
        max-height: 500px;
      }
    }
    .introduction {
      width: 50%;
      text-align: start;
      .title {
        font-size: 36px;
        font-weight: 400;
        margin-bottom: 18px;
        color: #fdfeff;
      }
      .subTitle {
        font-size: 24px;
        font-weight: 300;
        color: #fec544;
        margin-bottom: 18px;
      }
      .text {
        p {
          margin-bottom: 18px;
        }
      }
      .info-content {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          margin-bottom: 14px;
        }
      
      }
      .button {
        margin-top: 43px;
        display: flex;
        flex-direction: row;
        .download {
          background-color: #fec544;
          color: #070d1b;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          font-family: Roboto, sans-serif;
          line-height: 1.5em;
          font-weight: 500;
          padding: 12px 1.5em;
          border-radius: 50px;
          min-width: 170px;
          border: none;
          outline: none;
          transition: all 0.4s ease;
          text-transform: capitalize;
          cursor: pointer;
          margin-right: 30px;
        }
  
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .about {
    padding-top: 80px;
    .about-container {
      display: flex;
      flex-direction: column;
      gap: 0;
      width: 100%;
      .image {
        width: 100%;
        max-height: 500px;
        img {
          max-height: 400px;
        }
      }
      .introduction {
        width: 100%;
        .title {
          font-size: 26px;
          line-height: 36px;
        }
        .subTitle{
            font-size: 20px;
        }
        .button {
          padding: 0% !important;
          flex-direction: row; /* Stack buttons vertically on small screens */
          align-items: center; /* Align buttons to the start of the flex container */
          .download {
            margin: 0; /* Adjusts margin for buttons in mobile view */
            width: 50%; /* Ensures buttons take full width in mobile view */
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 481px) and (max-width: 850px) {
  .about {
    padding-top: 80px;
    .about-container {
      .image {
        width: 100%;
        max-height: 500px;
        img {
          max-height: 500px;
        }
      }
      .introduction {
        width: 100%;
        .title {
          font-size: 30px;
          line-height: 36px;
        }
        .button {
          padding: 0% !important;
          flex-direction: row; /* Stack buttons vertically on small screens */
          align-items: center; /* Align buttons to the start of the flex container */
          .download {
            margin: 0; /* Adjusts margin for buttons in mobile view */
            width: 50%; /* Ensures buttons take full width in mobile view */
            font-size: 14px;
          }
        }
      }
    }
  }
}
