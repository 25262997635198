@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.App {
  text-align: center;
}

body,
html {
  color: #a9adb8;
  background-color: #0a101e;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6em;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}
a{
  text-decoration: none;
  color: #a9adb8;
  padding-left: 5px;
}