.projects {
  color: #a9adb8;
  overflow: hidden;
  padding:5%;
  h1 {
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    color: #fec544;
  }
  .projectcard {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    height: 100%;
    flex-wrap: wrap; /* Allow flex items to wrap to the next line */
    .p-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 32%;
      text-align: start;
      box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
      border-radius: 7px;
      cursor: pointer;
      padding: 1rem 0.8rem;
      margin-bottom: 1rem; /* Add some space at the bottom for wrapping */
      .buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 0.5rem; 
        padding-top: 20px;
        position: relative;
        bottom: 0;
        a {
          padding: 0.5rem 1rem;
          white-space: nowrap;
          border-radius: 0.3rem;
          font-size: 1rem;
          font-weight: 600;
          border: none;
          cursor: pointer;
          transition: 0.2s ease-in-out;
          background-color: #a9adb8;
          text-decoration: none;
          color: #0a101e;
        }
        a:hover {
          background-color: #0a101e;
          color: #a9adb8;
        }
      }
      .p-image {
        height: 200px;
        overflow: hidden;
        border-radius: 7px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 7px;
          transition: 0.3s ease-in-out;
        }
      }
      .p-image-no-display{
        display: none;
      }
      h4 {
        font-size: 1.3rem;
        padding: 0.9rem 0 0.2rem 0;
      }
      .p-card:hover img {
        transform: scale(1.3);
        overflow: hidden;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .projects {
    .projectcard {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      .p-card {
        width: 100%;
        height: 500px;
      }
    }
  }
}
@media screen and (min-width: 481px) and (max-width: 850px) {
  .projects {
    .projectcard {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .p-card {
        width: 50%;
        height: 500px;
      }
    }
  }
}


